<template>
  <div>
    <el-dialog
      :visible.sync="innerVisible"
      :title="title"
      :fullscreen="fullscreen"
      class="dialogBox"
    >
      <div class="queryBox">
        <div class="queryBoxTop">
          <img src="../../../assets//optimizeImgs/drop.png" alt />
          <span>文件</span>
          <el-button type="primary" @click="handleAdd">新增分解</el-button>
          <el-button type="info" plain>调整顺序</el-button>
          <img src="../../../assets//optimizeImgs/delete.png" alt />
          <img src="../../../assets//optimizeImgs/edit.png" alt />
          <img src="../../../assets//optimizeImgs/refresh.png" alt />
        </div>
        <el-form :model="queryParams" ref="queryForm" :inline="true">
          <el-form-item label="公司名称:" prop="deptId">
            <el-select v-model="queryParams.companyName" clearable>
              <el-option
                v-for="item in options"
                :key="item.deptId"
                :label="item.deptName"
                :value="item.deptId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="文件类型:" prop="deptId">
            <el-select v-model="queryParams.companyName" clearable>
              <el-option
                v-for="item in options"
                :key="item.deptId"
                :label="item.deptName"
                :value="item.deptId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="文件名称:" prop="deptId">
            <el-select v-model="queryParams.companyName" clearable>
              <el-option
                v-for="item in options"
                :key="item.deptId"
                :label="item.deptName"
                :value="item.deptId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="文件编号:" prop="deptId">
            <el-input v-model="queryParams.companyName" disabled></el-input>
          </el-form-item>
          <el-form-item label="文件附件:" prop="deptId">
            <el-input v-model="queryParams.companyName" disabled></el-input>
          </el-form-item>
          <el-form-item label="范围:" prop="deptId">
            <el-input v-model="queryParams.companyName" style="width: 505px"></el-input>
          </el-form-item>
          <el-form-item label="文件目标:" prop="deptId">
            <el-input v-model="queryParams.companyName" style="width: 424px"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary">保存</el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="compName" label="程序文件"></el-table-column>
        <el-table-column label="活动分解">
          <el-table-column label="一级分解"></el-table-column>
          <el-table-column label="二级分解"></el-table-column>
          <el-table-column label="三级分解"></el-table-column>
          <el-table-column label="四级分解"></el-table-column>
          <el-table-column label="五级分解"></el-table-column>
        </el-table-column>
        <el-table-column label="责任单位">
          <el-table-column label="公司"></el-table-column>
          <el-table-column label="部门"></el-table-column>
          <el-table-column label="班组"></el-table-column>
        </el-table-column>
        <el-table-column prop="compName" label="责任人"></el-table-column>
        <el-table-column prop="compName" label="活动类型"></el-table-column>
        <el-table-column label="输入输出">
          <el-table-column label="类型"></el-table-column>
          <el-table-column label="接口"></el-table-column>
        </el-table-column>
        <el-table-column prop="compName" label="相关记录"></el-table-column>
        <el-table-column prop="compName" label="周期"></el-table-column>
        <el-table-column prop="compName" label="标准"></el-table-column>
      </el-table>
    </el-dialog>
    <!-- 新增弹框 -->
    <el-dialog :visible.sync="addVisible" title="新增分解" width="600px">
      <el-form ref="form" :model="form" label-width="auto">
        <el-form-item label="一级分解:">
          <el-input v-model="form.name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="二级分解:">
          <el-input v-model="form.name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="三级分解:">
          <el-input v-model="form.name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="四级分解:">
          <el-input v-model="form.name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="五级分解:">
          <el-input v-model="form.name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="责任单位:">
              <el-select v-model="form.companyName" placeholder="请选择" clearable>
                <el-option
                  v-for="item in options"
                  :key="item.deptId"
                  :label="item.deptName"
                  :value="item.deptId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="责任班组:">
              <el-select v-model="form.companyName" placeholder="请选择" clearable>
                <el-option
                  v-for="item in options"
                  :key="item.deptId"
                  :label="item.deptName"
                  :value="item.deptId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="责任部门:">
              <el-select v-model="form.companyName" placeholder="请选择" clearable>
                <el-option
                  v-for="item in options"
                  :key="item.deptId"
                  :label="item.deptName"
                  :value="item.deptId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="责任人:">
              <el-select v-model="form.companyName" placeholder="请选择" clearable>
                <el-option
                  v-for="item in options"
                  :key="item.deptId"
                  :label="item.deptName"
                  :value="item.deptId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="活动类别:">
              <el-select v-model="form.companyName" placeholder="请选择" clearable>
                <el-option
                  v-for="item in options"
                  :key="item.deptId"
                  :label="item.deptName"
                  :value="item.deptId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="输入输出类别:">
              <el-select v-model="form.companyName" placeholder="请选择" clearable>
                <el-option
                  v-for="item in options"
                  :key="item.deptId"
                  :label="item.deptName"
                  :value="item.deptId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="责任部门:">
              <el-select v-model="form.companyName" placeholder="请选择" clearable>
                <el-option
                  v-for="item in options"
                  :key="item.deptId"
                  :label="item.deptName"
                  :value="item.deptId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="输入输出接口:">
              <el-select v-model="form.companyName" placeholder="请选择" clearable>
                <el-option
                  v-for="item in options"
                  :key="item.deptId"
                  :label="item.deptName"
                  :value="item.deptId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="周期:">
              <el-select v-model="form.companyName" placeholder="请选择" clearable>
                <el-option
                  v-for="item in options"
                  :key="item.deptId"
                  :label="item.deptName"
                  :value="item.deptId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addVisible = false">保 存</el-button>
        <el-button @click="addVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
   
<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      innerVisible: this.visible,
      queryParams: {},
      options: [],
      tableData: [],
      addVisible: false,
      form: {},
    };
  },
  watch: {
    innerVisible(newValue) {
      this.$emit("update:visible", newValue);
    },
    visible(newValue) {
      this.innerVisible = newValue;
    },
  },
  methods: {
    handleAdd() {
      this.addVisible = true;
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../../assets/style/optimizeTable.less";
.dialogBox {
  ::v-deep .el-dialog {
    background: #dedede !important;
  }
  ::v-deep .el-dialog__header {
    background: #fff;
  }
  ::v-deep .el-dialog__body {
    padding: 0 !important;
    margin: 0 !important;
  }
  .queryBox {
    background: #f7f7f7;
    padding: 10px 20px 0 20px;
    .queryBoxTop {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      .el-button--info.is-plain,
      span,
      img {
        margin-right: 10px;
        cursor: pointer;
      }
    }
  }
}
.el-form-item {
  margin-bottom: 10px !important;
}
</style>